exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-cleaning-full-index-jsx": () => import("./../../../src/pages/cleaning_full/index.jsx" /* webpackChunkName: "component---src-pages-cleaning-full-index-jsx" */),
  "component---src-pages-cleaning-part-index-jsx": () => import("./../../../src/pages/cleaning_part/index.jsx" /* webpackChunkName: "component---src-pages-cleaning-part-index-jsx" */),
  "component---src-pages-contact-thanks-index-jsx": () => import("./../../../src/pages/contact/thanks/index.jsx" /* webpackChunkName: "component---src-pages-contact-thanks-index-jsx" */),
  "component---src-pages-demolition-full-index-jsx": () => import("./../../../src/pages/demolition_full/index.jsx" /* webpackChunkName: "component---src-pages-demolition-full-index-jsx" */),
  "component---src-pages-demolition-part-index-jsx": () => import("./../../../src/pages/demolition_part/index.jsx" /* webpackChunkName: "component---src-pages-demolition-part-index-jsx" */),
  "component---src-pages-estate-clearing-index-jsx": () => import("./../../../src/pages/estate_clearing/index.jsx" /* webpackChunkName: "component---src-pages-estate-clearing-index-jsx" */),
  "component---src-pages-faq-index-jsx": () => import("./../../../src/pages/faq/index.jsx" /* webpackChunkName: "component---src-pages-faq-index-jsx" */),
  "component---src-pages-gallery-index-jsx": () => import("./../../../src/pages/gallery/index.jsx" /* webpackChunkName: "component---src-pages-gallery-index-jsx" */),
  "component---src-pages-menu-index-jsx": () => import("./../../../src/pages/menu/index.jsx" /* webpackChunkName: "component---src-pages-menu-index-jsx" */),
  "component---src-pages-price-index-jsx": () => import("./../../../src/pages/price/index.jsx" /* webpackChunkName: "component---src-pages-price-index-jsx" */),
  "component---src-pages-reason-index-jsx": () => import("./../../../src/pages/reason/index.jsx" /* webpackChunkName: "component---src-pages-reason-index-jsx" */),
  "component---src-pages-reform-index-jsx": () => import("./../../../src/pages/reform/index.jsx" /* webpackChunkName: "component---src-pages-reform-index-jsx" */),
  "component---src-pages-staff-index-jsx": () => import("./../../../src/pages/staff/index.jsx" /* webpackChunkName: "component---src-pages-staff-index-jsx" */),
  "component---src-pages-temple-index-jsx": () => import("./../../../src/pages/temple/index.jsx" /* webpackChunkName: "component---src-pages-temple-index-jsx" */),
  "component---src-pages-unwanted-items-index-jsx": () => import("./../../../src/pages/unwanted_items/index.jsx" /* webpackChunkName: "component---src-pages-unwanted-items-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-2-about-index-jsx": () => import("./../../../src/templates/2_about/index.jsx" /* webpackChunkName: "component---src-templates-2-about-index-jsx" */),
  "component---src-templates-3-service-index-jsx": () => import("./../../../src/templates/3_service/index.jsx" /* webpackChunkName: "component---src-templates-3-service-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */),
  "component---src-templates-6-contact-index-jsx": () => import("./../../../src/templates/6_contact/index.jsx" /* webpackChunkName: "component---src-templates-6-contact-index-jsx" */)
}

